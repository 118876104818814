document.addEventListener('DOMContentLoaded', () => {
  const sections = Array.from(document.querySelectorAll('[data-form]'));

  const initializeSection =
    initializeSections(getTranslations)(handleFormReady)(handleFormSubmit);
  sections.forEach(initializeSection);
});

const getElementById = (id) => document.getElementById(id);
const formSelector = (form) => (query) => form.querySelector(query);

const initializeSections =
  (getTranslationsFunction) =>
  (handleReadyFunction) =>
  (handleSubmitFunction) =>
  (section) => {
    const formConfig = JSON.parse(section.getAttribute('data-form'));
    if (!formConfig) return;

    if (formConfig.cssClass?.includes('done-input-group-newsletter')) {
      window.newsletter = createDynamicNewsletterHandler(
        formConfig,
        getTranslationsFunction,
        handleReadyFunction,
        handleSubmitFunction
      );
    } else {
      const container = getElementById(formConfig.targetId);
      if (!container) {
        console.error(`Container with ID '${formConfig.targetId}' not found.`);
        throw new Error(
          `Container with ID '${formConfig.targetId}' not found.`
        );
      }

      renderForm(
        formConfig,
        getTranslationsFunction,
        handleReadyFunction,
        handleSubmitFunction,
        container
      );
    }
  };

const renderForm = (
  config,
  getTranslations,
  handleReady,
  handleSubmit,
  container
) => {
  hbspt.forms.create({
    ...config,
    target: `#${config.targetId}`,
    translations: getTranslations(),
    onFormReady: (form) => handleReady(form)(config),
    onFormSubmit: (form) => handleSubmit(form)(config.redirectUrl)
  });
};

const initializeForm =
  (translations) =>
  (handleReady) =>
  (handleSubmit) =>
  (config) =>
  (container) => {
    renderForm(
      config,
      () => translations,
      handleReady,
      handleSubmit,
      container
    );
  };

const getTranslations = () => ({
  fr: {
    required: 'Champ obligatoire.',
    phoneInvalidCharacters:
      'CE CHAMP NE PEUT CONTENIR QUE DES CHIFFRES, ET +()-. OU X.',
    invalidEmailFormat: 'Veuillez saisir une adresse e-mail valide.'
  }
});

const handleFormReady = (form) => (config) => {
  applyFormStyles(form);
  attachInputListeners(form);
  setupForms(config)(form);
};

const applyFormStyles = (form) => {
  applyClassToElements('label')('done-section-hubspot-form-group-label')(form);
  applyClassToElements('.hs-form-field')('done-section-hubspot-form-group')(
    form
  );
  adjustTextAreaRows(5)(form);
};

const applyClassToElements = (selector) => (className) => (parent) => {
  Array.from(parent.querySelectorAll(selector)).forEach((el) =>
    el.classList.add(className)
  );
};

const adjustTextAreaRows = (rows) => (form) => {
  const textarea = formSelector(form)('textarea');
  if (textarea) textarea.rows = rows;
};

const attachInputListeners = (form) => {
  const inputs = Array.from(form.querySelectorAll('input, select, textarea'));
  inputs.forEach((input) => {
    const label = formSelector(form)(`label[for="${input.id}"]`);
    if (label) {
      const updateLabel = () =>
        label.classList.toggle(
          'active',
          input.value || input === document.activeElement
        );

      updateLabel();
      ['focus', 'blur', 'input'].forEach((event) =>
        input.addEventListener(event, updateLabel)
      );
    }
  });
};

const setupForms = (config) => (form) => {
  if (config.redirectUrl?.includes('./typ-simulateur.html')) {
    initializeSimulatorMessage(form);
  } else if (config.cssClass?.includes('done-input-group-newsletter')) {
    setupNewsletterForm(form);
  }
};

const setupNewsletterForm = (form) => {
  const emailInput = formSelector(form)('input[name="email"]');
  const emailValue = getElementById('emailNewsletter')?.value;

  if (emailInput) {
    if (emailValue) emailInput.value = emailValue;
    emailInput.classList.add(
      'done-input-group-input',
      'done-input-group-input-newsletter'
    );

    form.addEventListener('submit', (event) => {
      event.preventDefault();
      form.submit();
    });
  }
};

const initializeSimulatorMessage = (form) => {
  const savings = localStorage.getItem('savings');
  const stockValue = localStorage.getItem('stockValue');

  if (savings && stockValue) {
    const messageContent = `Bonjour,
      Je souhaite avoir plus d'informations concernant les produits que je veux valoriser d'une valeur de ${stockValue} euros et des économies estimées grâce à la solution don de ${savings} euros. Merci !`;

    const messageField = formSelector(form)(
      '#message-b6491972-71f1-4078-af7f-f25379b61c28'
    );
    const labelField = formSelector(form)(
      '#label-message-b6491972-71f1-4078-af7f-f25379b61c28'
    );
    if (messageField) {
      setTimeout((messageField.value = messageContent), 1000);
      if (labelField) labelField.classList.add('active');
    }
  }
};

const handleFormSubmit = (form) => (redirectUrl) => {
  const emailInput = formSelector(form)('input[name="email"]');
  const emailValue = emailInput?.value;

  if (emailValue) {
    localStorage.setItem('emailInput', emailValue);
    window.location.href = redirectUrl;
  } else {
    console.error('Email not found');
    throw new Error('email not found');
  }
};

const createDynamicNewsletterHandler = (
  config,
  getTranslationsFunction,
  handleReadyFunction,
  handleSubmitFunction
) => {
  const modal = getElementById('doneModalNewsletter');
  const emailInput = getElementById('emailNewsletter');
  const submitButton = getElementById('submitNewsletter');
  let hubspotEmailInput;

  const update = () => {
    if (emailInput.validity.valid) {
      submitButton.removeAttribute('disabled');
    } else {
      submitButton.setAttribute('disabled', 'true');
    }
  };

  const show = () => {
    const container = getElementById(config.targetId);
    if (!container) {
      console.error(`Container with ID '${config.targetId}' not found.`);
      throw new Error(`Container with ID '${config.targetId}' not found.`);
    }

    renderForm(
      config,
      getTranslationsFunction,
      handleReadyFunction,
      handleSubmitFunction,
      container
    );

    modal.showModal();
  };

  const close = () => modal.close();

  return { update, show, close };
};
